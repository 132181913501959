<!-- 职位管理 -->
<template>
  <div class="job_manage">
    <!-- 已完善资料 -->
    <div v-if="firm_data.licenseImage" class="content">
      <header>
        <div class="left">
          <Tag :checkable="obj.type != 1" :name="1" :checked="obj.type == 1" color="warning" @on-change="changeTag" :size="obj.type == 1?'large':'default'">全职职位</Tag>
          <Tag :checkable="obj.type != 2" :name="2" :checked="obj.type == 2" color="warning" @on-change="changeTag" :size="obj.type == 2?'large':'default'">小时工职位</Tag>

        </div>
        <div class="right">
          <Button type="warning" @click="$router.push({name:obj.type==1?'job_full':'job_Temporary'})">发布职位</Button>
        </div>
      </header>

      <!-- <div style="position:relative;"> -->
      <!-- <Spin size="large" fix v-if="spinShow"></Spin> -->

      <div v-if="or_empty">

        <section>
          <Tabs :animated="false" v-model="status" @on-click="changeTabs">
            <TabPane :label="label1" name="3"></TabPane>
            <TabPane :label="label2" name="1"></TabPane>
            <TabPane :label="label3" name="2"></TabPane>
            <TabPane :label="label4" name="0"></TabPane>
          </Tabs>
        </section>
        <article>
          <Table max-height="640" :loading="loading" :columns="columns1" :data="Tabledata">

            <!-- 操作 -->
            <template slot-scope="{ row }" slot="details">
              <Button v-show="+status" type="error" size="small" @click="remove(row,0)">下架</Button>
              <Button type="primary" size="small" style="margin:0 5px" @click="$router.push({name:obj.type==1?'job_full':'job_Temporary',query:{id:row._id}})">{{+status?'修改':'重新发布'}}</Button>

              <Button v-show="status == 3 && firm_data.type && firm_data.type !=1" type="primary" size="small" @click="Refresh(row)">刷新</Button>

              <Button v-show="status != 3" type="error" size="small" @click="remove(row,-1)">删除</Button>

            </template>

          </Table>
        </article>
        <footer>
          <footer>
            <PageSize :total="total" @page_size="page_size" :page_in="obj.page" :size_in="obj.size" />

          </footer>
        </footer>
      </div>

      <!-- 未发布 -->
      <div class="noCompany" v-else>
        <img src="@/assets/images/noPosition.png" alt="">
        <p>您还未发布{{obj.type ==1?"全职":"小时工"}}职位，前去发布！</p>
        <Button type="warning" size="large" @click="$router.push({name:obj.type==1?'job_full':'job_Temporary'})">发布职位</Button>
        <!-- <Button type="warning" size="large" @click="go('firm_data','企业资料')">完善企业信息</Button> -->
      </div>

      <!-- </div> -->

    </div>
    <!-- 未完善资料 -->
    <div class="noCompany" v-else>
      <img src="@/assets/images/noCompany.png" alt="">
      <p>您还未完善企业信息，完善后可发布岗位！</p>
      <Button type="warning" size="large" @click="go('firm_data','企业资料')">完善企业信息</Button>
    </div>
  </div>
</template>

<script>
import PageSize from "@/components/PageSize.vue"
import { data1, data2, data3, data4 } from "./Form_head";

export default {
  data() {
    return {
      label1: (h, params) => {
        return h('div', [
          h('span', '已通过'),
          // h('Badge', {
          //   props: {
          //     count: this.statusCount[0],
          //     type: "warning"
          //   },
          //   style: {
          //     marginLeft: '7px'
          //   },
          // })
          h('span', ' . ' + this.statusCount[0])
        ])
      },
      label2: (h, params) => {
        return h('div', [
          h('span', '审核中'),
          h('span', ' . ' + this.statusCount[1])
        ])
      },
      label3: (h, params) => {
        return h('div', [
          h('span', '审核未通过'),
          h('span', ' . ' + this.statusCount[2])
        ])
      },
      label4: (h, params) => {
        return h('div', [
          h('span', '已结束'),
          h('span', ' . ' + this.statusCount[3])
        ])
      },
      total: 0,
      // spinShow: false,
      //表单ing
      loading: false,
      //是否未发布职位
      or_empty: false,
      status: '3',
      statusCount: [],
      obj: {
        type: 1,
        status: 3,
        key: '',
        page: 1,
        size: 10
      },
      columns1: data1,
      Tabledata: []
    }
  },
  props: ['firm_data'],
  components: {
    PageSize
  },
  methods: {


    //获取分页
    page_size(page, size) {
      this.obj.page = page
      this.obj.size = size
      this.get_data()
    },

    //改变类型全职or小时工
    changeTag(bool, name) {
      console.log(name);
      this.obj.type = name
      this.obj.page = 1
      this.status = "3"
      this.or_empty = false

      //改变表格表头
      this.columns1 = name == 1 ? data1 : data3

      this.get_data()
    },
    //改变通过与否
    changeTabs(name) {
      this.status = name
      this.obj.page = 1
      if (this.obj.type == 1) {
        this.columns1 = name != 2 ? data1 : data2
      } else {
        this.columns1 = name != 2 ? data3 : data4
      }
      this.get_data()
    },

    //刷新
    Refresh(item) {
      console.log(item);
      this.app("enterpriseOperators", "positionRefreshById", {
        positionId: item._id
      }).then(res => {
        if (res.code == 200) {
          this.$Message.warning('刷新成功')
        } else {
          this.$Message.error('刷新失败')
        }

      })
    },
    //删除
    remove(row, num) {
      this.$Modal.confirm({
        title: `是否确定${num == 0 ? '下架' : '删除'}该职位？`,
        closable: true,
        onOk: () => {
          this.app("enterpriseOperators", "disablePosition", {
            _id: row._id,
            status: num,
            mobile: localStorage.getItem('firm_mobile')
          }).then(res => {
            console.log(res);

            if (res.code == 200) {
              this.get_data()
              this.$Message.success(res.message)
            }
          })
        }
      })

    },

    //封装获取数据
    get_data() {
      // this.spinShow = true
      this.loading = true
      this.obj.enterprieseId = localStorage.getItem('firm_enterpriseId')
      this.obj.status = +this.status
      this.app("enterpriseOperators", "myPositions", this.obj).then(res => {
        console.log(res);
        //是否没发布职位
        res.statusCount.map((item, index) => {
          if (item) {
            this.or_empty = true
          }
        })
        //代表tab数量的数组
        this.statusCount = res.statusCount
        this.total = res.count
        this.Tabledata = res.result
        this.loading = false
        // this.spinShow = false

      })
    },



    //完善企业信息
    go(router, menu) {
      this.$router.push(router)
      this.$emit("setTitle", menu)
    },

  },
  created() {
    if (this.$route.query.id) {
      this.obj.type = this.$route.query.id
    }
    this.get_data()

  },
  updated() {
    if (!this.firm_data.licenseImage) {
      this.$Modal.confirm({
        title: '提示',
        content: '企业信息未完善',
        closable: true,
        onOk: () => {
          this.$router.push("firm_data")
          this.$emit("setTitle", "企业资料")
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./job_manage.less";
</style>
