import dayjs from 'dayjs'

//原始页面表头

//全职职业
export const data1 = [{
            title: '序号',
            type: 'index',
            width: 60,
            align: 'center',
            width: "80"
        },
        {
            title: '职位名称',
            key: 'name',
            align: 'center',
        },
        {
            title: '发布时间',
            key: 'publishDate',
            render: (h, params) => {
                return <span > { dayjs(params.row.publishDate).format("YYYY/MM/DD") } < /span>;
            },
            align: 'center',
        },
        {
            title: '薪酬范围',
            key: 'salary',
            align: 'center',
        },
        {
            title: '招聘人数',
            key: 'need_number',
            // render: (h, params) => {
            //     return <span > { params.row.gender == 3 ? '男女不限' : params.row.gender == 1 ? '男' : '女' } < /span>
            // },
            align: 'center',
        },
        {
            title: '学历',
            key: 'education',
            align: 'center',
        },
        {
            title: '操作',
            slot: 'details',
            align: 'center',
            width: "180"
        },

    ]
    //全职职业  //审核未通过表头
export const data2 = [{
        title: '序号',
        type: 'index',
        width: 60,
        align: 'center',
        width: "80"
    },
    {
        title: '职位名称',
        key: 'name',
        align: 'center',
    },
    {
        title: '发布时间',
        key: 'publishDate',
        render: (h, params) => {
            return <span > { dayjs(params.row.publishDate).format("YYYY/MM/DD") } < /span>;
        },
        align: 'center',
    },
    {
        title: '薪酬范围',
        key: 'salary',
        align: 'center',
    },
    {
        title: '招聘人数',
        key: 'need_number',
        // render: (h, params) => {
        //     return <span > { params.row.gender == 3 ? '男女不限' : params.row.gender == 1 ? '男' : '女' } < /span>
        // },
        align: 'center',
    },
    {
        title: '学历',
        key: 'education',
        align: 'center',
    },
    {
        title: '未通过原因',
        key: 'reviews',
        align: 'center',

    },
    {
        title: '操作',
        slot: 'details',
        align: 'center',
        width: "180"
    },

]



//小时工  
export const data3 = [{
            title: '序号',
            type: 'index',
            width: 60,
            align: 'center',
            width: "80"
        },
        {
            title: '职位名称',
            key: 'name',
            align: 'center',
        },
        {
            title: '发布时间',
            key: 'publishDate',
            render: (h, params) => {
                return <span > { dayjs(params.row.publishDate).format("YYYY/MM/DD") } < /span>;
            },
            align: 'center',
        },
        {
            title: '薪酬范围',
            key: 'daytime',
            align: 'center',
            render: (h, params) => {
                return <span > { params.row.minSalary }
                元 / 小时 < /span>
            },
        },
        {
            title: '工作时长',
            key: 'workhours',
            align: 'center',
        },
        {
            title: '每日工作时间',
            key: 'daytime',
            align: 'center',
            // render: (h, params) => {
            //   return <span>{params.row.minSalary}元/小时</span>
            // },
        },
        {
            title: '操作',
            slot: 'details',
            align: 'center',
            width: "180"
        },

    ]
    //小时工  //审核未通过表头
export const data4 = [{
        title: '序号',
        type: 'index',
        width: 60,
        align: 'center',
        width: "80"
    },
    {
        title: '职位名称',
        key: 'name',
        align: 'center',
    },
    {
        title: '发布时间',
        key: 'publishDate',
        render: (h, params) => {
            return <span > { dayjs(params.row.publishDate).format("YYYY/MM/DD") } < /span>;
        },
        align: 'center',
    },
    {
        title: '薪酬范围',
        key: 'daytime',
        align: 'center',
        render: (h, params) => {
            return <span > { params.row.minSalary }
            元 / 小时 < /span>
        },
    },
    {
        title: '工作时长',
        key: 'workhours',
        align: 'center',
    },
    {
        title: '每日工作时间',
        key: 'daytime',
        align: 'center',
        // render: (h, params) => {
        //   return <span>{params.row.minSalary}元/小时</span>
        // },
    },
    {
        title: '未通过原因',
        key: 'reviews',
        align: 'center',
        slot: 'reviews',
    },

    {
        title: '操作',
        slot: 'details',
        align: 'center',
        width: "180"
    },

]