<template>
  <div style="text-align:right;padding: 20px 60px 0 0;">
    <Page :total="total" :current="page" :page-size="size" show-sizer show-total :page-size-opts="[10, 20, 30, 40,50]" @on-change="page_change" @on-page-size-change="size_change" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // total: 0,
      page: 1,
      size: 10
    }
  },
  props: {
    total: Number,
    page_in: Number,
    size_in: Number,
  },
  methods: {
    page_change(page) {
      this.page = page
      this.$emit("page_size", this.page, this.size)
    },
    size_change(size) {
      this.size = size
      this.$emit("page_size", this.page, this.size)
    },

  },
  //监听改变试图
  watch: {
    page_in(val) {
      this.page = val
    },
    size_in(val) {
      this.size = val
    }
  }
}

</script>

<style>
</style>